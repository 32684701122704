.AssDocumentation_center {



}







.TreeSelect_warp{
  //background-color: pink;
  background-color: #fff;
  position: fixed !important;
  max-height: 258px !important;
  min-width: 820px;
  overflow: auto;

  .treeSelectedD{
    img{
      width: 20px;
      height: 20px;
      margin-top: -3px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }



  .ant-select-tree{

    .ant-select-tree-treenode-switcher-open{
      .ant-select-tree-child-tree{
        //background: skyblue;
        //max-height: 350px;
      }
    }

    .ant-select-tree-treenode-checkbox-checked{
      .ant-select-tree-checkbox{
        .ant-select-tree-checkbox-inner{
          background-color: #40a9ff;
          border-color: #40a9ff;

          //  &::after{
          //    top: 50%;
          //    left: 50%;
          //    width: 8px;
          //    height: 8px;
          //    //background-color: #1890ff;
          //    background-color: #40a9ff!important;
          //    border: 0;
          //    -webkit-transform: translate(-50%, -50%) scale(1);
          //    -ms-transform: translate(-50%, -50%) scale(1);
          //    transform: translate(-50%, -50%) scale(1);
          //    opacity: 1;
          //    content: ' ';
          //}
        }
      }

    }
  }
  .ant-select-tree-checkbox-checked::after{
    border: 1px solid #40a9ff;
  }
  .ant-select-tree-checkbox:hover{
    .ant-select-tree-checkbox-inner{
      border: 1px solid #40a9ff;
    }
  }
  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after{
    background-color: #40a9ff;
  }






}




