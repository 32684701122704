.task_center{
    :global{

        .content{
            background-color: transparent;
            .cont-top{
                display: flex;
                justify-content: space-between;
                height: 68px;
                line-height: 68px;
                margin-bottom:20px!important;
                background-color: #ffffff;
                .button-topL{
                    cursor: pointer;
                    margin-left: 42px ;
                    span{
                        border: transparent;
                        font-size: 16px;
                        line-height: 23px;
                        color: #888888;
                    }

                }
                .button-topR{
                    margin-right: 30px;
                    .btnN{
                        margin: 0 9px;
                        background-color: #40a9ff ;
                        border: 1px solid #40a9ff;
                        color: #ffffff;
                    }
                    .btn{
                        margin: 0 9px;
                        color: #40a9ff;
                        border: 1px solid #40a9ff;
                    }
                }

            }
            .cont-bottom{
                display: flex;
                justify-content: space-between;
                //float: left;
                height: 100% !important;
                background-color: transparent;
                .cont-bottomL{
                    background-color: #ffffff;
                    width: 60%;
                    min-height: 1170px;
                    margin-right: 20px;
                    .cont-bottomLT{
                        margin: 22px 30px;
                        display: flex;
                        line-height: 24px;
                        width: 100%;
                        float: left;
                        height: 24px;
                        font-weight: 550;
                        p{
                            display: inline;
                            margin-right: 15px;
                            margin-top: 4px;
                            float: left;
                            width: 4px;
                            height: 18px;
                            background: linear-gradient(90.16deg, #40a9ff 0.19%, #40a9ff 99.92%);
                            border-radius: 10px;
                        }

                        titlee{
                            float: left;
                            font-weight: 550;

                        }
                    }
                    .cont-bottomLM{
                        margin-left: 50px;
                        border-bottom: 1px solid #F3F3F3;
                        .titleT{
                            display: flex;
                            margin-right: 30px;
                            width: calc(100% - 30px);
                            float: left;
                            float: left;
                            .taskidd{
                                display: inline;
                                padding: 0 5px;
                                height: 20px;
                                font-size: 12px;
                                color: #3E78ED;
                                border: 1px solid #3E78ED;
                                box-sizing: border-box;
                                border-radius: 100px;
                                text-align: center;
                                line-height: 21px;
                                margin-right: 10px;
                                margin-top: 2px;

                            }
                            .taskidT{
                                font-size: 16px;
                                color: #333333;
                                display: inline;
                                margin-top: 1px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;

                            }
                        }
                        .titleB{
                            float: left;
                            .titleBDiv{
                                margin: 20px 30px 20px 13px;
                                .titleBDF{
                                    display: flex;
                                    .titleBtaskDescribe{
                                        height: auto;
                                        overflow-y: auto;
                                        word-wrap:break-word;
                                    }
                                }
                                .titleB0{
                                    font-family: Noto Sans SC;
                                    font-style: normal;
                                    font-size: 14px;
                                    color: #333333;
                                    display: inline-block;
                                    min-width: 85px;
                                    text-align: left;
                                    .svg-icon{
                                        margin-right: 16px;
                                        float: left;
                                        margin-top: 3px;
                                    }
                                }
                                .user{
                                    margin: 2px 5px 0 0;
                                }
                                .claim{
                                    cursor:pointer;
                                }
                                .claim:hover{
                                    text-decoration:underline;
                                    color: #40a9ff;
                                }
                                .yiwancheng{
                                    color: #27CDA2
                                }

                                .yiyuqi{
                                    color: #40a9ff
                                }

                                .putong{
                                    color: #3E78ED
                                }
                                .jinji{
                                    color: #F3B714
                                }
                                .feichangjinji{
                                    color: #40a9ff
                                }
                            }
                            .dropdownSelect{
                                margin-top: 1.9px;
                                margin-left: 10px;

                            }
                            .dropdownOption{
                                display: flex;
                                margin-top: 3px!important;
                            }


                        }
                    }
                    .cont-bottomLB{
                        margin: 30px 30px 20px 50px;
                        width: calc(100% - 80px);
                        float: left;
                        .cont-bottomLBTitle{
                            display: flex;
                            justify-content: space-between;

                            .titleL{
                                font-size: 16px;
                                line-height: 23px;
                                display: flex;
                                align-items: center;
                                font-weight: 550;
                                color: #333333;
                            }
                            .titleR{
                                color: #40a9ff;
                                cursor: pointer;
                                //margin-right: 10px;
                                span{
                                    margin-left: 10px;

                                    font-size: 14px;


                                }
                            }
                        }

                        .cont-bottomLBList{


                        }
                        .zirenwuList{
                            margin-top: 4px;
                            margin-right: 5px;
                            border: 1px solid #F3F3F3;
                            max-height: 202px;
                            width: 100%;
                            float: left;
                            overflow-y: auto;
                            ul{
                                margin: 0;
                                li{
                                    list-style: none;
                                    border-bottom: 1px dashed #F3F3F3;
                                    height: 50px;
                                    line-height: 50px;
                                    width: 100%;
                                    float: left;
                                    .zirenwuListTitle1{
                                        width: calc(100% - 320px);
                                        a{
                                            width: 100%;
                                            .zrw{
                                                color: #333333;
                                                display: inline-block;;
                                                //max-width: 200px;
                                                width: 100%;
                                                overflow: hidden;
                                                white-space: nowrap;
                                                text-overflow: ellipsis;
                                            }
                                        }
                                    }
                                    .zirenwuListTitle{
                                        float: left;
                                        height: 50px;
                                        .taskCode{
                                            background: #40a9ff;
                                            border-radius: 20px;
                                            height: 18px;
                                            padding: 0 10px;
                                            color: #ffffff;
                                            margin: 0 9px 0 16px;
                                        }
                                        .zirenwuListTitleCh{
                                        }
                                        span{
                                            font-size: 14px;
                                        }
                                        .zirenwuListTitleCh{
                                            margin:0 20px;
                                        }
                                        .zrws{
                                            color: #333333;
                                        }
                                        .zrw{
                                            color: #40a9ff;
                                        }

                                    }
                                    .zirenwuListright{
                                        float: right;
                                        margin-right: 50px;
                                        .ant-avatar{
                                            width: 28px;
                                            height: 28px;
                                            margin: 0 8px 0 20px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .cont-bottomLB1{
                        .titleL{
                            font-size: 16px;
                            line-height: 23px;
                            display: flex;
                            align-items: center;
                            font-weight: 550;
                            color: #333333;
                        }
                        margin-top: 0px;
                        .zirenwuList{
                            margin-bottom: 20px;
                            .intitle{
                                //display: flex;
                                margin: 13px 43px 25px 18px;
                                justify-content: space-between;
                                font-weight: 550;
                                .svg-icon{
                                    margin: 3px 6px 0 0;
                                    float: left;
                                }
                                .addzhichu{
                                    color: #40a9ff;
                                    cursor: pointer;
                                }
                            }
                            .glzjlist{
                                margin-left: 15px;
                                li{
                                    color: #333333;
                                    height: 20px;
                                    line-height: 20px;
                                    margin-bottom: 20px;
                                    border: none;
                                    span{
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                        .zirenwuList1{
                            margin-bottom: 13px;
                            .titleR{
                                color: #40a9ff;
                                cursor: pointer;
                            }
                            .glzjlist{
                                li{
                                    display: flex;
                                    justify-content: space-between;
                                    margin-right: 43px;
                                    width: calc(100% - 43px);
                                    .quxiaogl{
                                        cursor: pointer;
                                        color: #40a9ff;
                                        float: left;
                                        margin: 0;
                                    }
                                    .bk{
                                        width: 1px;
                                        height: 8px;
                                        background: #C4C4C4;
                                        margin: 5px 10px;
                                        float: left;
                                    }
                                }
                                .cont-bottomRT2UIDD{
                                    float: left;
                                    margin: 0;
                                    span{
                                        margin: 0;
                                        color: #40a9ff;
                                    }
                                }
                            }
                        }
                        .guanlizj{
                            height: 100px;
                            margin-bottom: 0;
                            .intitle{
                                margin-right: 28px;
                                margin-bottom: 20px;
                                .addzhichu{
                                    float: right;
                                    font-weight: normal;
                                }

                            }
                        }
                    }

                }
                .cont-bottomR{
                    background-color: transparent;
                    //float: right;
                    width: 40%;

                    .cont-bottomRT{
                        background-color: #ffffff;
                        margin-bottom: 20px;
                        height: 268px;
                        .titleR{
                            color: #40a9ff;
                            cursor: pointer;
                            //margin-right: 30px;
                            span{
                                margin-left: 10px;
                                font-size: 14px;

                            }
                        }

                        .cont-bottomRT1{
                            display: flex;
                            justify-content: space-between;
                            //background-color: #ffffff;
                            height: 40px!important;
                            line-height: 69px!important;
                            margin: 22px 30px;
                            display: flex;
                            p{
                                display: inline;
                                margin-right: 15px;
                                margin-top: 26px;
                                float: left;
                                width: 4px;
                                height: 18px;
                                background: linear-gradient(90.16deg, #40a9ff 0.19%, #40a9ff 99.92%);
                                border-radius: 10px;
                            }

                            titlee{
                                float: left;
                                font-weight: 550;
                            }

                        }
                        .cont-bottomRT2{
                            margin: 22px 30px;
                            overflow-y: auto;
                            height: 171px;
                            .cont-bottomRT2UI{
                                margin-right:20px ;
                                display: flex;
                                justify-content: space-between;
                                height: 35px;
                                min-width: 500px;
                                line-height: 35px;
                                border-bottom: 1px solid #F3F3F3;
                                //span{
                                //  cursor: pointer;
                                //
                                //}
                                .cont-bottomRT2UID{
                                    span{
                                        margin-left: 5px;
                                        cursor: pointer;
                                    }
                                    .cont-bottomRT2UIDS{
                                        display: inline-block;
                                        width: 20px;
                                    }
                                    .cont-bottomRT2UIDD{
                                        display: inline-block;
                                        cursor: pointer;
                                        color:#40a9ff;
                                        .cont-bottomRT2UIDD:hover{
                                            color: #40a9ff;
                                        }

                                    }
                                }
                            }
                            .cont-bottomRT2UI2{
                                margin-right:20px ;
                                display: flex;
                                justify-content: space-between;
                                height: 35px;
                                min-width: 500px;
                                line-height: 35px;
                                border-bottom: 1px solid #F3F3F3;
                                background-color:#f5f5f5;
                                cursor: default;
                                .cont-bottomRT2UID2{
                                    span{
                                        margin-left: 5px;
                                    }
                                    .cont-bottomRT2UIDS2{
                                        display: inline-block;
                                        width: 20px;
                                    }
                                    .cont-bottomRT2UIDD2{
                                        display: inline-block;
                                        .cont-bottomRT2UIDD2:hover{
                                            color: #40a9ff;
                                        }
                                    }
                                    .cont-bottomRT2UIDD3{
                                        color:#40a9ff;
                                        cursor:pointer;
                                        display: inline-block;
                                        .cont-bottomRT2UIDD3:hover{
                                            color: #40a9ff;
                                        }
                                    }

                                }
                            }
                        }
                    }
                    .cont-bottomRB{
                        display: flex;
                        background-color: peachpuff;
                        height: 300px;
                        margin-top: 20px;

                        .cont-bottomRBT{
                            width: 100px;
                            margin: 22px 30px;

                            p{
                                display: inline;
                                margin-right: 15px;
                                margin-top: 6px;
                                float: left;
                                width: 4px;
                                height: 18px;
                                background: linear-gradient(90.16deg, #40a9ff 0.19%, #40a9ff 99.92%);
                                border-radius: 10px;
                            }

                            titlee{
                                float: left;
                                font-weight: 550;
                            }

                        }
                        .cont-bottomRBB{


                        }


                    }
                }
            }
        }
        .xmgl-title{
            font-weight: 550;
            font-size: 20px;
            text-indent: 31px;
            color: #333333;
            margin: 22px 0;
            width: 100%;
            float: left;
            line-height: 24px;
            .rightshezhi{
                color: #888888;
                float: right;
                font-size: 14px;
                margin-right: 30px;
                .img-shezhi{
                    margin: 16px 8px 0 0;
                    float: left;
                }
                span{
                    float: left;
                    text-indent: 0;
                    cursor: pointer;
                }
                .line{
                    width: 1px;
                    margin: 0 12px 0 12px;
                    border-right: 1px solid #888888;
                    float: left;
                    height: 16px;
                    margin-top: 16px;
                }
            }
            &::after{
                margin-top: 5px!important;
                margin-top: 5px!important;
                margin-right: -15px!important;
                margin-left: 30px!important;
                float: left;
                display: block;
                width: 4px!important;
                height: 18px!important;
                //margin: 2px 15px 0 0;
                //background-color: #40a9ff!important;
                border-radius: 10px;
                background: linear-gradient(90.16deg, #40a9ff 0.19%, #40a9ff 99.92%);
                content: '';

            }

        }
        .left-xmzt{
            height: 170px;
            background: #FFFFFF;
            //margin-bottom: 20px;
            .hegezt{
                margin: 20px 30px;
                height: 80px;
                width: calc( 100% - 60px);
                border-radius: 6px;
                display: flex;
                position: relative;
                .type-left{
                    font-weight: 500;
                    font-size: 18px;
                    color: #27CDA2;
                    line-height: 80px;
                    margin: 0 18px 0 20px;
                }
                .line{
                    width: 1px;
                    height: 19px;
                    background: #C4C4C4;
                    margin: 30px 20px 0 0;
                }
                .hegeul{
                    li{
                        list-style: none;
                        margin: 20px 0 11px 0;
                        line-height: 17px
                    }
                    li:last-child{
                        margin: 0;
                    }
                    .name{
                        margin-right: 8px;
                    }
                }
                .xmtype{
                    position: absolute;
                    right: -10px;
                    top: -11px;
                    transform: rotate(15deg)
                }
            }
            .hegebg{
                background: rgba(39, 205, 162, 0.1);
            }
            .buhegebg{
                background: #40a9ff;
                opacity: 0.1;
            }
        }
        .left-dongtai{
            height: 490px;
            .rightbotTabs{
                list-style: none;
                .ant-tabs-bar{
                    margin-bottom: 0;
                }
                .ant-tabs-nav{
                    height: 50px;
                    width: 100%;
                    .ant-tabs-tab{
                        width: 50%;
                        margin: 0;
                        text-align: center;
                        color: #333333;
                        font-size: 16px;
                    }
                    .ant-tabs-tab-active{
                        color: #40a9ff
                    }
                }
                .ant-tabs-ink-bar{
                    display: none !important;
                }
                .pinglun{
                    .showImage{
                        width: 100%;
                        height: 47px;
                        border-bottom: 1px solid #EEEDED;
                        .showImageCont{
                            float: right;
                            margin-right: 42px;
                            line-height: 47px;
                            color: #40a9ff;
                            font-size: 14px;
                            cursor: pointer;
                            span{
                                position: relative;
                                svg{
                                    position: absolute;
                                    top: 0;
                                    right: 8px
                                }
                            }
                        }
                    }
                    .pingluncont{
                        margin: 30px;
                        height: 680px;
                        overflow-y: auto;
                        margin-bottom: 15px;
                        margin-right: 20px;
                        li{
                            width: calc(100% - 30px);
                            float: left;
                            list-style: none;
                            font-size: 14px;
                            color: #333333;
                            margin-bottom: 30px;
                            margin-right: 30px;
                            .touxing{
                                float: left;
                                margin-right: 10px;
                                img{
                                    width: 45px;
                                    height: 45px;
                                    border-radius: 45px;
                                }
                            }
                            .plcontright{
                                float: left;
                                width: calc(100% - 55px);
                                .righttop1{
                                    margin-bottom: 11px;
                                    color: #333333;
                                    width: 100%;
                                    float: left;
                                    span{
                                        font-size: 12px;
                                        line-height: 17px;
                                        float: left;
                                    }
                                    .time{
                                        color: #888888;
                                        margin-left: 10px;
                                        float: left;
                                    }
                                    .delete{
                                        float: right;
                                        cursor: pointer;
                                        margin-right: 10px;
                                        .reddelete{
                                            margin: 2px 10px 0 0;
                                        }
                                    }
                                }
                                .text{
                                    margin-bottom: 20px;
                                    width: 100%;
                                    float: left;
                                }
                                .imglist{
                                    li{
                                        float: left;
                                        margin-right: 15px;
                                        width: 90px;
                                        margin-bottom: 15px;
                                        img{
                                            width: 90px;
                                            height: 90px;
                                        }
                                    }
                                    li:last-child{
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                    .uploadImgul{
                        height: 80px;
                        margin-bottom: 10px;
                        display: flex;
                        li{
                            width: 80px;
                            height: 80px;
                            margin-left: 10px;
                            list-style: none;
                            position: relative;
                            .deleteImg{
                                position: absolute;
                                right: -8px;
                                top: -8px;
                                z-index: 11;
                                cursor: pointer;
                            }
                            img{
                                width: 100%;
                                height: 100%;
                                filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.05));
                                border-radius: 3px;
                            }
                        }
                    }
                    .pinglunfooter{
                        width: 100%;
                        height: 146px;
                        border-top: 1px solid #F3F3F3;
                        .upload-img{
                            width: 20px;
                            height: 20px;
                            .img-box{
                                background: none;
                            }
                            .ant-upload-select-picture-card{
                                margin: 0;
                                padding: 0;
                                border: none;
                                background: none;
                                .ant-upload{
                                    padding: 0;
                                    div{
                                        p{
                                            margin: 6px 0 0px 20px;
                                        }
                                        img{
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                        .shuruk{
                            margin: 7px 12px 20px 12px;
                            width: calc(100% - 24px);
                            float: left;
                            border-radius: 6px;
                            height: 96px;
                            background: #F8F8F8;
                            font-size: 12px;
                            .ant-mentions{
                                width: 100%;
                                height: 75px;
                                background: none;
                                border: none !important;
                                resize: none;
                                box-shadow: none !important;
                                margin: 0;
                                textarea{
                                    background: none;
                                    border: none;
                                    height: 75px;
                                }
                            }
                            .fasong{
                                color: #40a9ff;
                                float: right;
                                margin-right: 15px;
                                cursor: pointer;
                                line-height: 10px;
                            }

                        }
                        .imglist{

                        }
                    }

                    .ant-row{
                        margin-bottom: 0;
                        .ant-upload.ant-upload-select-picture-card{
                            border: none;
                            //width: 17px;
                            //height: 17px;
                            background: none;
                        }
                        .upload-img .img-box{
                            background: none !important;
                            .ant-upload{
                                padding: 0;
                                .img-show{
                                    img{
                                        width: 80px;
                                        height: 80px;
                                    }
                                }
                            }
                        }
                    }

                }
            }
            .dtlist{
                max-height: calc(100vh - 32px);
                //height: 300px;
                overflow-y: auto;
                margin: 26px 30px 26px 30px;
                li{
                    width: 100%;
                    display: flex;
                    position: relative;
                    .yuan{
                        position: absolute;
                        top: 4px;
                        left: 97px;
                        width: 12px;
                        height: 12px;
                        background: #fff;
                        border: 2px solid #40a9ff;
                        padding: 2px;
                        box-sizing: border-box;
                        border-radius: 50%;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-align-items: center;
                        align-items: center;
                        -webkit-justify-content: center;
                        justify-content: center;
                    }
                    .left-time{
                        min-width: 90px;
                        margin-right: 13px;
                    }
                    .right-sj{
                        padding-bottom: 15px;
                        position: relative;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        li{
                            margin-bottom: 10px;
                            .right-name{
                                margin: 0 23px 0 24px;
                            }
                            .right-action{
                                min-width:100px;
                                color: #888888;
                                margin-right: 12px;
                            }
                            .right-message{
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }

                    }
                    .right-sj:after{
                        position: absolute;
                        left: 0px;
                        top: 16px;
                        height: 100%;
                        width: 2px;
                        background: #e5e9ec;
                        content: "";
                        background: #F3F3F3;
                    }
                }
                li:first-child{
                    .yuan{
                        background: #40a9ff;
                        border: 2px solid #c3e0f7;
                    }
                }
                li:last-child{
                    .right-sj:after{
                        width: 0;
                    }
                }
            }
        }
        .dongtaipingjia{
            height: 1090px;
            width: 100%;
            float: left;
        }
        .canyuren{
            height: 130px;
            margin-bottom: 20px;
            width: 100%;
            float: left;
            .QuestionMark{
                float: right;
                font-size: 14px;
                line-height: 20px;
                margin-right: 20px;
                .tooltip{
                    position: relative;
                    .svg-icon{
                        margin-right: 10px;
                        position: absolute;
                        right: 0;
                        top: 3px;
                    }
                }
            }
            .canyurenul{
                display:block;
                white-space:nowrap;
                width:100%;
                overflow:auto;
                height: 63px;
                ul{
                    padding: 0 0 0 48px;
                    margin: 0;
                }
                li{
                    display:inline-block;
                    margin-right: 12px;
                    width: 45px;
                    height: 45px;
                    list-style: none;
                    margin-top: -18px;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                    }
                }
                li:last-child{
                    position: relative;
                    .add{
                        position: absolute;
                        top: 18px;
                    }
                }
            }
        }
    }
}


.TreeSelect_warpDiv{
    .TreeSelect_warpC{
        .ant-select-dropdown-menu  ant-select-dropdown-menu-root ant-select-dropdown-menu-vertical{

          background-color: #8dbcf4 !important;//青瓷蓝

        }

    }

}
.popover{
    :global{
        .popoverD{
            display: block;
            cursor: pointer;
            border: transparent;

        }
        .popoverD:hover{
            color: #40a9ff!important;

        }
        .popoverD:active{
            color: #40a9ff!important;
            border-color: transparent!important;

        }
    }
}
.popoverTool{
    :global{
        .popoverD{
            cursor: pointer;

        }
        .popoverD:hover{
            color: #40a9ff!important;
        }
    }

}

.downloadColor{
    color:#000000A6;
    color:#40a9ff;
}



.dropdownOption{
    top: 453px!important;
}

.bianjirenwu_modal{
    :global{
        .ant-modal-body{
            max-height:400px;
            overflow-y: auto;
        }
        .ant-form-explain{
            margin-left: 0px;
        }
    }
}
.PopoverCont{
    :global{
        height: 98px;
        .left{
            float: left;
            margin: 21px 10px 0 14px;
            img{
                width: 30px;
                height: 30px;
                border-radius: 30px;
            }
        }
        .right{
            float: left;
            .righttop{
                height: 56px;
                width: 140px;
                margin-top: 11px;
                border-bottom: 1px solid #EEEDED;
                color: #333333;
                span{
                    line-height: 20px;
                    margin-bottom: 5px;
                    display: block;
                    width: 100%;
                }
            }
            .remove{
                color: #40a9ff;
                line-height: 20px;
                margin-top: 5px;
                display: block;
                width: 100%;
                padding-left: 23px;
                cursor: pointer;
            }
        }
    }
}
.RelatedContent{
    :global{
    .jixiaoss{
        padding:0 30px;
            .ant-cascader-picker{
                width: 230px;
            }
            .ant-cascader-input.ant-input,.ant-input{
                width: 230px;
                float: left;
                margin-right: 30px;
            }
            .ant-btn{
                float: right;
            }
            .ant-btn-primary{
                background: #40a9ff;
                margin-left: 17px;
            }
        }
    .ant-table-thead>tr > th{
        background: #F7F9FB;
        font-weight: 550;
        padding: 9px 16px;
    }
    .ant-modal-body{
        padding: 0;
        .ant-tabs-nav-scroll{
            margin-top: 18px;
        }
        tr{
            .ant-radio-checked .ant-radio-inner,.ant-radio:hover .ant-radio-inner{
                border-color: #40a9ff;

            }
            .ant-radio-inner::after{
                background-color: #40a9ff;
            }
        }
        .ant-tabs-content{
            height: 325px;
            .ant-tree{
                li{
                    line-height: 40px;
                    padding: 0;
                    overflow-x: hidden;
                    .ant-tree-switcher{
                        margin-top: 5px
                    }
                    .ant-tree-node-content-wrapper{
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        .ant-tree-iconEle{
                            margin-top: 8px
                        }
                    }
                    .ant-tree-node-content-wrapper:hover{
                        background: #F7F9FB;
                    }
                }
                .ant-tree-treenode-selected{
                    line-height: 40px;
                }
                .ant-tree-node-selected{
                    background: #F7F9FB;
                }
            }

        }
        .ant-tabs-ink-bar{
            background: #40a9ff;
            width: 28px !important;
            height: 3px;
            left: 16px;
        }
        .ant-tabs-nav{
            margin-left: 14px;
            .ant-tabs-tab-active,.ant-tabs-tab{
                color: #333333;
                font-size: 16px;
            }
        }
        .treewrap{
            width: calc(100% - 60px);
            margin-left: 30px;
            .ant-select-open .ant-select-selection{
                border-color: #EBEBEB;
                box-shadow: none;
            }
            .ant-input-search{
                margin-bottom: 0 !important;
                border-radius: 3px;
                input{
                    border: 1px solid #EBEBEB;
                }
            }
            .ant-tree{
                border: 1px solid #EBEBEB;
                border-radius: 3px;
                border-top: none;
            }
        }
        .bonuswrap{
            .ant-checkbox-checked  {
                .ant-checkbox-inner{
                    background-color: #40a9ff;
                    border-color: #40a9ff;

                }

            }
            .ant-checkbox-indeterminate .ant-checkbox-inner::after{
                background: #40a9ff;
            }
            .ant-checkbox-checked::after{
                border-color: #40a9ff;
            }
            .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
                border-color: #40a9ff !important;
            }

            .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
                span{
                    margin-right: 0 !important;
                }
                padding: 9px 16px;
            }
        }
        tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
            background: #F7F9FB;;
        }
        tr>td{
            padding: 14px 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    }
}

.mentionsnameOption{
    height: 40px;
    :global{
        .mentionsnametximg{
            width: 25px;
            height: 25px;
            border-radius: 25px;
            margin-right: 10px;
            margin-top: 5px;
            float: left;
        }
        span{
            line-height: 35px;
            margin-top: -2px;
            float: left;
            display: block;
        }
    }
}
.mentionsnameOption:hover,.mentionsnameOption:focus,.mentionsnameOption:active{
    background: #F8F8F8 !important;
    color: #40a9ff;
}
.mentionsnameMentionstitle{
    :global{
        .canyuren{
            position: relative;
            height: 40px;
            line-height: 40px;
            margin-left: 10px;
            &::before{
                width: 3px;
                height: 12px;
                background: #40a9ff;
                position: absolute;
                content: '';
                left: -10px;
                top: 4px;
            }
        }
    }
}
.mentionsnameMentionstitle:hover{
    background: none !important;
}
.ant-select-dropdown{
    .ant-select-tree{
       padding: 0;
        li{
            padding: 0 4px;
            margin: 0;
            line-height: 40px;
            .ant-select-tree-node-content-wrapper:hover{
                background: #F7F9FB;
            }
        }
    }
}

.CYRstyle{
    :global{
        .ant-modal-content{
            .ant-modal-body{
                height: 400px;
            }

        }
    }
}
//评论查看图片弹窗
.showImageModal{
    :global{
        .Empty-conet-ss{
            span{
                text-align: center;
            }
            img{
                margin-left: calc(50% - 160px);
            }
        }
        .imglistul{
            padding: 0 26px;
            max-height: calc(100vh - 260px);
            overflow-y: auto;
            .clear{
                clear:both;
            }
            .spanli{
                list-style: none;
                float: left;
                margin: 0 40px 20px 0;
                img{
                    width: 120px;
                    height: 120px;
                }
                cursor: pointer;
            }
            .spanli:nth-child(5n){
                margin-right: 0;
            }
            .monthCont{
                width: 100%;
                float: left;
                p{
                    width: 100%;
                }
            }
        }
    }
}







