.quanxiansz_center{
  .textare-wrap{
    width: 100%;
    height: 100%;
    border: 1px solid #d9d9d9;
    padding-bottom: 20px;
    position: relative;
    float: left;
    .ant-input{
      border: none;
    }
    .ant-input:focus{
      box-shadow: none
    }
  }
  .user-management-count{
    position: absolute;
    bottom:0;
    right:0;
    padding-right:5px;
  }
}

.taskPriorityOption{
   //background-color: pink!important;
  position: fixed!important;
 }
