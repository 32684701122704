// 按钮样式
.ant-btn {
  font-family: Microsoft YaHei!important;
  border-radius: 5px ;
  /*  background: #fff;*/
}

.ant-btn-primary {
  font-weight: 300;
  // background: #06a5d8 !important;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  border: none !important;
}
.ant-btn-bgcolor{
  font-weight: 300;
  background: #ccc !important;
  color: rgba(255, 255, 255, 1) !important;
  text-align: center;
  border: none !important;
}
.ant-btn-size{
  font-weight: 300;
  color: rgba(0, 0, 0, .5) !important;
  text-align: center !important;
  border: none !important;
}
.create-button {
  width: 132px;
  height: 35px !important;
  line-height: 35px;
  font-size: 18px !important;
  font-weight: 300;
}

.search-button {
  width: 108px !important;
  height: 35px !important;
  line-height: 35px;
  font-size: 14px;
  font-weight: 400;
}

.reset-button {
  width: 108px !important;
  height: 35px !important;
  border: 1px solid rgba(230, 230, 230, 1) !important;
  font-size: 14px;
  font-weight: 400;
  color: rgba(6, 165, 216, 1) !important;
  line-height: 32px;
}

// 出版社授权里面的按钮
.press-auth-button {
  width: 170px !important;
  height: 38px !important;
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
}

.step-button {
  width: 150px !important;
  height: 44px !important;
  font-size: 18px;
  font-family: NotoSansCJKsc-Light, NotoSansCJKsc;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
}

// 取消新建按钮
.cancel-button {
  width: 150px !important;
  height: 44px !important;
  background: #fff;
  color: #06a5d8 !important;
  font-size: 16px;
  font-family: NotoSansCJKsc-Regular, NotoSansCJKsc;
  font-weight: 400;
  border: none !important;
}
// 修改 查看 审核
.multiple {
  font-size: 14px;
  font-family: NotoSansCJKsc-Regular, NotoSansCJKsc;
  font-weight: 400;
  color: rgba(6, 165, 216, 1);
  line-height: 20px;
}

// 删除样式 禁用
.delete {
  font-size: 14px;
  font-family: NotoSansCJKsc-Regular, NotoSansCJKsc;
  font-weight: 400;
  color: rgba(249, 109, 87, 1);
  line-height: 20px;
}

// 重新送审
.error-message {
  font-size: 14px;
  font-family: NotoSansCJKsc-Regular, NotoSansCJKsc;
  font-weight: 400;
  color: rgba(227, 0, 0, 1);
  line-height: 20px;
}

// 启用
.start-using {
  font-size: 14px;
  font-family: NotoSansCJKsc-Regular, NotoSansCJKsc;
  font-weight: 400;
  color: rgb(135, 178, 0);
  line-height: 20px;
}

.sub-title {
  font-size: 18px;
  font-family: NotoSansCJKsc-Bold, NotoSansCJKsc;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  line-height: 18px;
}

// .ant-select-selection{
//   width: 150px;
//   height: 38px;
//   margin-right: 10px;
// }
.system-setting-top{
  padding: 0!important;
  border: 0!important;
  border-bottom: 2px solid #e6e6e6!important;
}
body{
  background: #f6f7fb;
}
//宽度
.main-cont{
  width:1200px;
  display: block;
  margin:0 auto;
  //输入框提示文字
  .ant-form-explain{
    font-size: 12px;
    color: #A82025;
    padding-top:4px;
  }
  .has-error .ant-input, .has-error .ant-input:hover,
  .has-error .ant-input-number, .has-error .ant-time-picker-input{
    border-color: #A82025;
  }
  //文本域
  .TextArea-pic{
    resize: none;
  }
  //.TextArea-pic::-webkit-scrollbar {
  //  /*滚动条整体样式*/
  //  width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
  //  height: 1px;
  //}
  //.TextArea-pic::-webkit-scrollbar-thumb {
  //  /*滚动条里面小方块*/
  //  border-radius: 10px;
  //  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  //  background   : #535353;
  //}
  //.TextArea-pic::-webkit-scrollbar-track {
  //  /*滚动条里面轨道*/
  //  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  //  border-radius: 10px;
  //  background   : #ededed;
  //}
  TextArea{
    resize: none;
  }
}
.main-bg{
  background: #f6f7fb;
  input{
    -webkit-transform: translate3d(0, 0, 0) !important;
    -webkit-user-select:text !important;
  }
}
.clearfix:after{
  content:'';
  display:block;
  height:0;
  overflow:hidden;
  clear:both;
  zoom:1;
}
//弹窗
.Modal-mainpic{
  .ant-modal-header{
    border:none;
  }
  .ant-modal-title{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120.7%;
    color: #2A283D;
    display: block;
    text-align: center;
    padding:20px 0;
    border:none;
    .anticon{
      color:#40a9ff!important;
      margin-right:8px;
      display: inline-block!important;
    }
  }
  .ant-modal-body{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120.7%;
    color: #D3D3D3;
    text-align: center;
    display: block;
  }
  .ant-modal-footer{
    border:none;
    text-align: center;
    padding:28px 0;
    .ant-btn{
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      border-radius: 5px;
      margin:0 12px;
      height:38px;
      line-height: 36px;
      padding:0 42px;
      span{
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #888888;
      }
    }
    .ant-btn.ant-btn-primary{
      background: #40a9ff;
      border-radius: 5px;
      span{
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
}
.Modal-mainpic-html{
  .Modal-but{
    width:660px!important;
  }
  .ant-modal-header{
    border:none;
    padding:0;
  }
  .ant-modal-title{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120.7%;
    color: #2A283D;
    display: block;
    text-align: center;
    padding:16px 45px;
    border:none;
    background: #F8F8F8;
    div{
      display: flex;
      align-items: center;
      img{
        margin-right:14px;
      }
    }
    .anticon{
      color:#40a9ff!important;
      margin-right:8px;
      display: inline-block!important;
    }
  }
  .ant-modal-body{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #2A283D;
    padding:40px 45px;
    display: block;
    text-align: center;
    .text-conta{
      ol{
        background: rgba(64, 169, 255, 0.03);
        border: 1px solid #40a9ff;
        box-sizing: border-box;
        border-radius: 3px;
        padding:48px 68px;
        margin-bottom:30px;
      }
    }
    li{
      margin-bottom:16px;
      text-align: left;
      //border-left:4px solid #40a9ff;
      padding:0 14px;
    }
    font{
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      //color: rgba(42,40,61,0.5);
      color: #40a9ff;
      background: url('../../../../assets/img/synchrodatment/icon-7.png')no-repeat left center;
      background-size: 20px 20px;
      padding-left:34px;
      //padding-left:21px;
    }
  }
  .ant-modal-footer{
    border:none;
    text-align: right;
    padding:10px 30px 10px 0;
    border-top: 1px solid #F3F3F3;
    .ant-btn{
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      border-radius: 5px;
      height:38px;
      line-height: 36px;
      padding:0 27px;
      span{
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #888888;
      }
    }
    .ant-btn.ant-btn-primary{
      background: #40a9ff;
      border-radius: 5px;
      margin-left:30px;
      span{
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
}
//首页上传
.Modal-mainpic-cont{
  .ant-modal-header{
    border:none;
  }
  .ant-modal-title{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120.7%;
    color: #2A283D;
    display: block;
    text-align: center;
    padding:20px 0;
    border:none;
    div{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        margin-right:14px;
      }
    }
    .anticon{
      color:#40a9ff!important;
      margin-right:8px;
      display: inline-block!important;
    }
  }
  .ant-modal-body{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #2A283D;
    padding:0 15%;
    display: block;
    text-align: center;
    li{
      margin-bottom:16px;
      text-align: left;
      //border-left:4px solid #40a9ff;
      padding:0 14px;
    }
    font{
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: rgba(42,40,61,0.5);
      //background: url('../assets/images/img-icona.png')no-repeat left center;
      //padding-left:21px;
    }
  }
  .ant-modal-footer{
    border:none;
    text-align: center;
    padding:28px 0;
    .ant-btn{
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      border-radius: 5px;
      margin:0 12px;
      height:38px;
      line-height: 36px;
      padding:0 42px;
      span{
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #888888;
      }
    }
    .ant-btn.ant-btn-primary{
      background: #40a9ff;
      border-radius: 5px;
      span{
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
      }
    }
    .but-up{
      .ant-btn{
        margin-left:0;
        padding:0;
      }
    }
  }
}
.Modal-mainpic-cont-uploading{
  .ant-modal{
    width:550px!important;
  }
  .ant-modal-header{
    border:none;
    padding:0;
  }
  .ant-modal-title{
    padding: 16px 45px;
    background: #F8F8F8;
    display: block;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-family: Noto Sans SC;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    color: #333333;
    div{
      img{
        margin-right:14px;
      }
    }
    .anticon{
      color:#40a9ff!important;
      margin-right:8px;
      display: inline-block!important;
    }
  }
  .ant-modal-body{
    margin: 0!important;
    font-family: Noto Sans SC;
    font-style: normal;
    font-weight: normal;
    line-height: 23px;
    color: #333333;
    padding: 50px 0;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    .text-conta{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img{
      margin-right: 15px;
      vertical-align: middle;
      float: left;
    }
  }
  .ant-modal-footer{
    border:none;
    text-align: right;
    margin-top: 0!important;
    width: 100%;
    display: block;
    border-top: 1px solid #F3F3F3;
    padding: 10px 30px;
    .ant-btn{
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      border-radius: 5px;
      height: 38px;
      line-height: 36px;
      padding: 0 27px;
      span{
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #888888;
      }
    }
    .ant-btn.ant-btn-primary{
      background: #40a9ff;
      border-radius: 5px;
      margin-left: 30px;
      span{
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
      }
    }
    .but-up{
      .ant-btn{
        margin-left:0;
        padding:0;
      }
    }
  }
}
//首页上传
//注意事项
.Modal-mainpic-matters{
  .ant-modal{
    width:660px!important;
    .ant-modal-body{
      padding:25px 30px;
      .contacts-form{
        .ant-form-item{
          margin-bottom:0;
          flex: 1 1;
        }
        .col{
          display: flex;
          align-items: center;
          margin:10px 0;
          .ant-input{
            display: inline-block;
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
            box-sizing: border-box;
            border-radius: 3px;
            padding: 10px;
            height: 40px;
          }
          span{
            min-width:120px;
          }
        }
        .col-span{
          display: flex;
          align-items: center;
          margin:10px 0;
          span{
            min-width:120px;
          }
          .ant-form-item{
            margin-right:10px;
          }
          .ant-input{
            display: inline-block;
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
            box-sizing: border-box;
            border-radius: 3px;
            padding: 10px;
            height: 40px;
          }
        }
      }
      .text-conta{
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #333333;
        b{
          font-weight: bold;
        }
        i{
          list-style: initial;
          color:#40a9ff;
          font-weight: bold;
        }
      }
    }
    .ant-modal-footer{
      border-top: 1px solid #F3F3F3;
      padding:10px 30px;
      .modal_btn_j{
        .ant-btn{
          background: #FFFFFF;
          border: 1px solid #EBEBEB;
          box-sizing: border-box;
          border-radius: 5px;
          height:40px;
          padding:0 27px;
          span{
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 38px;
            text-align: center;
            color: #888888;
          }
          &.ant-btn-primary{
            background: #40a9ff;
            border-radius: 5px;
            margin-left:30px;
            span{
              color:#ffffff;
            }
          }
        }
      }
    }
  }
}
.font-num{
  text-align: right;
  font-size: 12px;
  line-height: 16px;
  color: #b6b7b8;

}
.step-button-box{
  margin:0 auto;
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  background: #ffffff;
  padding:32px 0;
  .main-cont{
    background: #ffffff;
    padding:32px 0;
    display: flex;
    justify-content:center;
    align-items: center;
  }
  .but-li{
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 5px;
    padding:0 50px;
    height:39px;
    line-height: 37px;
    margin:0 12px;
    span{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #888888;
    }
  }
  .but-li.ant-btn-primary{
    background: #40a9ff;
    span{
      color: #ffffff;
    }
  }
}
//下拉菜单
.Date-main-select{
  font-family:Microsoft YaHei!important;
  .ant-select-dropdown-menu-item{
    &:hover{
      background: #40a9ff!important;
      color:#ffffff;
      opacity: 1;
    }
  }
  .ant-select-dropdown-menu-item-selected{
    background: #40a9ff!important;
    color:#ffffff;
    opacity: 1;
  }
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled){
    background: #40a9ff!important;
    color:#ffffff;
    opacity: 1;
  }
}
//日历弹窗
.Date-main{
  font-family:Microsoft YaHei;
  .ant-calendar-today .ant-calendar-date{
    border-color: #40a9ff;
    color:#40a9ff;
  }
  .ant-calendar-selected-day .ant-calendar-date{
    background: #40a9ff;
    color: #ffffff;
  }
  .ant-calendar-footer{
    .ant-calendar-today-btn{
      color:#40a9ff;
    }
  }
  .ant-calendar-date:hover{
    background: #40a9ff;
    color: #ffffff;
  }
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month{
    background: #40a9ff;
    color: #ffffff;
  }
  .ant-calendar-month-panel-month:hover{
    background: #40a9ff;
    color: #ffffff;
  }
  ant-calendar-month-panel-year-select-content:hover{
    color: #40a9ff;
  }
  .ant-calendar-month-panel-year-select:hover{
    color: #40a9ff;
  }
}
//警告提示弹窗
.content-warp{
  text-align: center;
  width:550px!important;
  .ant-modal-body{
    padding:0!important;
  }
  .ant-modal-confirm-body-wrapper{
    text-align: center;
  }
  .anticon{
    display: none!important;
  }
  .ant-modal-confirm-title{
    padding:16px 45px;
    background: #F8F8F8;
    div{
      display: flex;
      align-items: center;
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: #333333;
      img{
        margin-right:14px;
      }
    }
    .anticon{
      color:#40a9ff!important;
      margin-right:8px;
      display: inline-block!important;
    }
  }
  .ant-modal-confirm-content{
    margin:0!important;
    font-family: Noto Sans SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #333333;
    padding:50px 0;
    img{
      margin-right:15px;
    }
  }
  .ant-modal-confirm-btns{
    text-align: right;
    margin-top:0!important;
    width:100%;
    display: block;
    float: left;
    border-top: 1px solid #F3F3F3;
    padding:10px 30px;
    .ant-btn{
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      border-radius: 5px;
      height: 38px;
      line-height: 36px;
      padding: 0 27px;
      span{
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #888888;
      }
    }
    .ant-btn.ant-btn-danger,.ant-btn.ant-btn-primary{
      background: #40a9ff;
      border-radius: 5px;
      margin-left:30px;
      span{
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF;
      }
    }
    .but-up{
      .ant-btn{
        margin-left:0;
        padding:0;
      }
    }
  }

}
body{
  font-family:Microsoft YaHei!important;
}
*{
  font-family:Microsoft YaHei;
}
.Task-bottom-main {
  #leftmain{
    max-height: calc(100% - 252px);
    min-height: calc(100% - 252px);
  }
  .left{
    overflow: auto;
    position: fixed;
    top: 456px;
    float: left;
    margin-top:36px!important;
    width: 290px;
    background: #ffffff;
    padding: 28px 0 41px;
    max-height: calc(100% - 212px);
    min-height: calc(100% - 212px);
    box-shadow: 1px 1px 1px rgba(51,51,51,0.03);
    z-index:2;
    // 滚动条设置
    &::-webkit-scrollbar {
      width: 5px;//y轴上的滚动条宽度
      height: 5px;//x轴上滚动条高度
    }
    &::-webkit-scrollbar-track {
      border-radius: 2px; /*滚动条的背景区域的圆角*/
      background-color:#f4f4f4; /*滚动条的背景颜色*/
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px; /*滚动条的圆角*/
      background-color:rgba(64,169,255, 0.1); /*滚动条的背景颜色*/
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color:#40a9ff;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:active {
      background-color:#40a9ff;
      cursor: pointer;
    }
  }
  .right{
    float: right;
    width:100%!important;
  }
}
.step-top-common.step-top-common-active{
  //border-bottom: 1px solid #EEEEEE;
  display: block;
  margin-bottom: 40px;
  padding: 32px 0;
  text-align: center;
  width: 100%;
  position: relative;
  &::after{
    position: absolute;
    left:0;
    bottom:0;
    border-bottom: 1px solid #EEEEEE;
    width:100%;
    content:"";
  }
  h3{
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 18px;
    height: 20px;
    color: #2A283D;
    padding: 0 16px 0 41px;
    position: relative;
    margin-bottom: 0;
  }
  h3::after {
    position: absolute;
    left: 0;
    top: 50%;
    //height: 100%;
    //background: #40a9ff;
    //width: 4px;
    content: "";
    width:30px;
    height:30px;
    background: url('../../../../assets/img/synchrodatment/icon-active.png')no-repeat;
    background-size: 30px 30px;
    border-radius: 50%;
    margin-top:-15px;
  }
  .anticon{
    margin-left: 8px;
  }
}
.ScheduledTask .ProjectSchool-bottom .bottom-warp .card-main .ant-card-body .col-list .form-list-a ul table{
  width:100%;
}
//媒体查询
@media (min-width: 1024px){
  .main-cont{
    width:1200px;
  }
  //.login-wrap-main .login-obtn .list{
  //  width:82%!important;
  //}
  .Comment-Double-index .Choose-top .row-content .ul li{
    width:20% !important;
  }
  .Task-bottom-main .left{
    width:264px!important;
  }
  .Task-bottom-main .right{
    width: calc(100% - 300px)!important;
  }
  .form-component,.target-form{
    padding: 20px 10px 10px!important;
  }
  .form-component .form-box{
    width:83%!important;
  }
  .target-form .form-obtn{
    padding: 0 15px 10px!important;
  }
  .ScheduledTask .ProjectSchool-bottom .bottom-warp .card-main .ant-card-body .col-list .form-list-a td .Item-li .ant-col-sm-4{
    width:40% !important;
  }
  .ScheduledTask .ProjectSchool-bottom .bottom-warp .card-main .ant-card-body .col-list .form-list-a td .Item-li .ant-col-sm-20{
    width:60% !important;
  }
  .ScheduledTask .ProjectSchool-bottom .bottom-warp .card-main .ant-card-body .col-list .form-list-b li .Item-li .ant-col-sm-4{
    width: 46%!important;
  }
  .ScheduledTask .ProjectSchool-bottom .bottom-warp .card-main .ant-card-body .col-list .form-list-b li .Item-li .ant-col-sm-20{
    width: 54%!important;
  }
  .form-assignment .ant-col-sm-4{
    width:25% !important;
  }
  .form-assignment .ant-col-sm-20{
    width: 71% !important;
  }
  .form-component .total-box{
    display: block!important;
    padding: 1px 20px!important;
  }
  .target-form .form-obtn .form-box-second .ant-form-item-label{}
}
@media (min-width: 1280px){
  .main-cont{
    width:1200px;
  }
  //.login-wrap-main .login-obtn .list{
  //  width: 72%!important;
  //}
  //.Comment-Assignment .ul-top .li{
  //  width:155px!important;
  //}
  .form-component .total-box{
    display: block!important;
    padding: 1px 20px!important;
  }
}
@media (min-width: 1366px){
  .main-cont{
    width:1280px;
  }
  .main-cont-warp{
    width:1200px!important;
  }
  //.login-wrap-main .login-obtn .list{
  //  width: 72%!important;
  //}
  //.Comment-Assignment .ul-top .li{
  //  width:180px!important;
  //}
  .form-component .total-box{
    display: flex!important;
  }
}
@media (min-width: 1440px){
  .main-cont{
    width:1366px;
  }
  .main-cont-warp{
    width:1200px!important;
  }
  //.login-wrap-main .login-obtn .list{
  //  width: 72%!important;
  //}
  //.Comment-Assignment .ul-top .li{
  //  width:190px!important;
  //}
  .form-component .total-box{
    display: flex!important;
  }
}
@media (min-width: 1680px){
  .main-cont{
    width:1440px;
  }
  .main-cont-warp{
    width:1200px!important;
  }
  //.login-wrap-main .login-obtn .list{
  //  width: 72%!important;
  //}
  //.login-wrap-main .login-obtn .list .left-login{
  //  background: url(../assets/images/left-bg.png) no-repeat center;
  //  padding: 7% 35px;
  //}
  //.Comment-Assignment .ul-top .li{
  //  width:200px!important;
  //}
  .form-component .total-box{
    display: flex!important;
  }
}
@media (min-width:1920px){
  .main-cont{
    width:1680px;
  }
  .main-cont-warp{
    width:1200px!important;
  }
  //.login-wrap-main .login-obtn .list .left-login{
  //  background: url(../assets/images/left-bg.png) no-repeat center;
  //  padding: 7% 35px;
  //}
  //.login-wrap-main .login-obtn .list{
  //  width: 72%!important;
  //}
  //.Comment-Assignment .ul-top .li{
  //  width:230px!important;
  //}
  .Task-bottom-main .left{
    width: 290px!important;
  }
  .Task-bottom-main .right {
    width: calc(100% - 300px) !important;
  }



  .Comment-Double-index .Choose-top .row-content .ul li{
    width:17%!important;
  }
  .form-component,.target-form{
    padding: 20px 50px 0!important;
  }
  .form-component .form-box{
    width:76% !important;
  }
  .target-form .form-obtn{
    padding: 0 30px 10px!important;
  }
  .ScheduledTask .ProjectSchool-bottom .bottom-warp .card-main .ant-card-body .col-list .form-list-a td .Item-li .ant-col-sm-4{
    width:34% !important;
  }
  .ScheduledTask .ProjectSchool-bottom .bottom-warp .card-main .ant-card-body .col-list .form-list-a td .Item-li .ant-col-sm-20{
    width:66% !important;
  }
  .ScheduledTask .ProjectSchool-bottom .bottom-warp .card-main .ant-card-body .col-list .form-list-b li .Item-li .ant-col-sm-4{
    width: 39%!important;
  }
  .ScheduledTask .ProjectSchool-bottom .bottom-warp .card-main .ant-card-body .col-list .form-list-b li .Item-li .ant-col-sm-20{
    width: 61%!important;
  }
  .form-assignment .ant-col-sm-4{
    width:20% !important;
  }
  .form-assignment .ant-col-sm-20{
    width: 74% !important;
  }
  .form-component .total-box{
    display: flex!important;
  }
}
.adding-items{
  margin: 0;
  padding-bottom: 10px;
  width: 100%;
  overflow: hidden;
  background: #fff;
  li{
    width: 100%;
    text-align: right;
    .but{
      margin-right: 40px;
      height:30px;
      background: #40a9ff;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding:0 6px 0 13px;
      border-color:#40a9ff;
      color:#ffffff;
      padding:0 6px 0 13px;
      border:1px solid #40a9ff;
      line-height: 30px;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      span{
        color:#ffffff;
      }
      i{
        list-style: initial;
        font-style: normal;
        background: url('../../../../assets/img/synchrodatment/icon-29.png')no-repeat center;
        background-size:12px 12px;
        color: #ffffff;
        margin:0.5px 5px 0 0;
        width:12px;
        height:12px;
        display: inline-block;
      }
      //&:hover{
      //  background: #40a9ff;
      //  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      //  border-radius: 3px;
      //  padding:0 6px 0 13px;
      //  border-color:#40a9ff;
      //  color:#ffffff;
      //  span{
      //    color:#ffffff;
      //  }
      //  i{
      //    background: url('../assets/images/icon-11.png')no-repeat center;
      //    background-size:12px 12px;
      //  }
      //}
      &.but-a{
        i{
          background: url('../../../../assets/img/synchrodatment/icon-30.png')no-repeat center;
          background-size:12px 12px;
        }
        //&:hover{
        //  i{
        //    background: url('../assets/images/icon-13.png')no-repeat center;
        //    background-size:12px 12px;
        //  }
        //}
      }
    }
  }
}


//12-27目标绩效
//左侧
.Double-left-table{
  padding:20px 0;
  display: block;
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{
    left:auto;
    right:15px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding:0 15px;
    margin-bottom:20px;
    height:20px;
    line-height: 20px;
  }
  .ant-collapse{
    border:none;
    background-color:initial;
    .ant-collapse-item{
      background-color:initial;
      border-bottom:none;
      .icon-img{
        margin:0 6px;
        width:18px;
        height:18px;
        display: inline-block;
      }
      .title{
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 550;
        font-size: 14px;
        line-height: 20px;
        color: #2A283D;
        position: relative;
        padding-left:24px;
        &::after{
          position: absolute;
          left:0;
          width:15px;
          height:14px;
          background: url('../../../../assets/img/synchrodatment/icon-2.png')no-repeat left center;
          background-size: 15px 14px;
          top:50%;
          margin-top:-7px;
          content:"";
        }
        &.calssEd{
          &::after{
            background: url('../../../../assets/img/synchrodatment/icon-14.png')no-repeat left center;
            background-size: 15px 14px;
          }
        }
      }
      .title-a{
        padding-left:24px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 550;
        font-size: 14px;
        line-height: 120.7%;
        color: #2A283D;
        text-indent: 14px;
        cursor: pointer;
        &::after{
          position: absolute;
          left: 35px;
          top: 0;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background:rgba(42, 40, 61, 0.4);
          margin-top: 4px;
          content: "";
          transition: all 0.3s;
        }
        &:hover{
          &::after{
            left:32px;
            width: 8px;
            height: 8px;
          }
        }
        &.twoactive{
          &::after{
            left:35px;
            width: 6px;
            height: 6px;
            margin-top: 3px;
            background: #548B3B;
          }
        }
      }
    }
    .ant-collapse-content{
      border-top:none;
      .ant-collapse-content-box{
        padding:0;
        .ant-collapse-item > .ant-collapse-header{
          margin-bottom:6px;
        }
        ul{
          display: block;
          width:100%;
          margin-bottom:0;
          .but{
            text-align: center;
            .ant-btn, .ant-btn:active, .ant-btn:focus{
              outline:none;
              box-shadow: none;
              background: none;
              //border:none;
            }
            .ant-btn{
              margin: 10px 0 0 89px;
              display: flex;
              align-items: center;
              padding:0 11px;
              min-width:116px;
              height:24px;
              background: rgba(64,169,255, 0.06);
              border: 1px solid #40a9ff;
              box-sizing: border-box;
              border-radius: 3px;
              box-shadow: none!important;
              text-shadow: none!important;
              &::selection{
                background: none;
              }
              [ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node{
                display: none;
              }
              &:focus{
                box-shadow: none!important;
                text-shadow: none!important;
              }
              span{
                font-family: Noto Sans SC;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 120.7%;
                color:#40a9ff;
              }
              i{
                display: inline-block;
                font-style: initial;
                list-style: initial;
                background: url('../../../../assets/img/synchrodatment/icon-31.png')no-repeat left center;
                background-size: 13px 12px;
                width:13px;
                height:12px;
                margin-right: 5px;
                display: inline-block;
              }
              //&:hover{
              //  background: #40a9ff;
              //  border-color: #40a9ff;
              //  span{
              //    color:#ffffff;
              //  }
              //  i{
              //    background: url('../assets/images/icon-3.png')no-repeat left center;
              //    background-size: 14px 14px;
              //  }
              //}
            }
          }
          li{
            display: block;
            min-height:36px;
            padding: 11px 0 11px 70px;
            width:100%;
            padding-left:70px;
            cursor: pointer;
            position: relative;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            .icon-img{
              position: absolute;
              left:35px;
              top:0;
              margin-top:13px;
              width:18px;
              height:18px;
              display: inline-block;
            }
            &::after{
              position: absolute;
              left:0;
              top:0;
              background: #F07661;
              border: 1px solid #40a9ff;
              box-sizing: border-box;
              border-radius: 500px;
              width:2px;
              height:20px;
              margin-top:12px;
              content:"";
              opacity: 0;
              -webkit-transition: all 0.3s;
              transition: all 0.3s;
            }
            em{
              list-style: initial;
              font-style: normal;
              display:inline-flex;
              align-items: center;
              margin-left:11px;
              position: absolute;
              top:14px;
              left: 197px;
              i{
                list-style: initial;
                font-style: normal;
                display: inline-block;
                margin:0 4px;
                img{
                  width:12px;
                  height:12px;
                  display: block;
                }
              }
            }
            span{
              padding-left:20px;
              position: relative;
              font-family: Noto Sans SC;
              font-style: normal;
              font-weight: 550;
              font-size: 12px;
              line-height: 18px;
              color: rgba(42, 40, 61, 0.5);
              width:142px;
              word-break:break-all;
              word-wrap:break-word;
              display: inline-block;
              &::after{
                position: absolute;
                left:4px;
                top:0;
                width:6px;
                height:6px;
                border-radius: 50%;
                background:rgba(42, 40, 61, 0.4);
                margin-top:6px;
                content:"";
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
              }
            }
            &:hover{
              background: rgba(255, 153, 135, 0.05);
              &::after{
                opacity: 1;
              }
              span{
                color: #2A283D;
                &::after{
                  left:-2px;
                  width:8px;
                  height:8px;
                  margin-top:6px;
                }
              }
            }
            &.active{
              background: rgba(255, 153, 135, 0.05);
              &::after{
                opacity: 1;
              }
              span{
                color: #2A283D;
                &::after{
                  left:-2px;
                  width:8px;
                  height:8px;
                  margin-top:6px;
                  background: #40a9ff;
                }
              }
            }
            &.on{
              span::after{
                background: #548B3B;
              }
            }
          }
        }
      }
    }
  }
  .ul-title{
    margin-bottom:22px;
  }
  .addpanel{
    margin: 0 0 15px 15px;
    padding-left: 24px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 550;
    font-size: 14px;
    line-height: 120.7%;
    color: #2A283D;
    text-indent: 14px;
    cursor: pointer;
    position: relative;
    &::after{
      position: absolute;
      left: 20px;
      top: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background:rgba(42, 40, 61, 0.4);
      margin-top: 4px;
      content: "";
      transition: all 0.3s;
    }
    &:hover{
      &::after{
        left:16px;
        width: 8px;
        height: 8px;
      }
    }
    &.addon{
      //color:#2BB078;
      &::after{
        background: #548B3B;
      }
    }
    &.addactive{
      //color:#40a9ff;
      &::after{
        left:16px;
        width: 8px;
        height: 8px;
        margin-top: 3px;
        background: #40a9ff;
      }
    }
  }
}

//预览页右侧
.top-right{
  position: absolute;
  right:37px;
  top:28px;
  background:rgba(64,169,255,0.05);
  padding:13px 17px;
  display: inline-block;
  text-align: center;
  h6{
    display: block;
    width:100%;
    font-family: Noto Sans SC;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #333333;
    margin-bottom:14px;
  }
  span{
    background: #40a9ff;
    display: inline-block;
    text-align: center;
    i{
      list-style: initial;
      font-style: normal;
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      color: #FFFFFF;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      padding:0 14px;
      b{
        list-style: initial;
        font-style: normal;
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
}

//增加指标弹窗
.add-indicator{
  .ant-modal-header{
    padding:12px 30px;
    border-bottom: 1px solid #F3F3F3;
  }
  .ant-modal-body{
    padding:20px 40px;
    display: block;
    min-height:188px;
    .indicator-div{
      display: flex;
      align-items: center;
      span{
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        display: inline-flex;
        align-items: center;
        min-width:82px;
        text-align: left;
        i{
          list-style: initial;
          font-style: normal;
          font-family: PingFang HK;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          color: #FF3000;
        }
      }
      .ant-input{
        display: inline-block;
        margin-left:15px;
        background: #FFFFFF;
        border: 1px solid #EBEBEB;
        box-sizing: border-box;
        border-radius: 3px;
        padding:10px;
        height:40px;
      }
    }
  }
  .ant-modal-footer{
    border-top: 1px solid #F3F3F3;
    padding:10px 30px;
    .ant-btn{
      padding:7px 27px;
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      border-radius: 5px;
      height:40px;
      span{
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #888888;
      }
      &.ant-btn-primary{
        background: #40a9ff;
        border-radius: 5px;
        border-color:#40a9ff;
        span{
          color:#ffffff;
        }
      }
    }
  }
}

.hint_content .footer .cancel{
  color: #40a9ff;
  border: 1px solid #40a9ff;
}
.hint_content .footer .i_see{
  background: #40a9ff;
}


//暂无数据
.Empty-content{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  min-height:300px;
  .text{
    text-align: center;
    img{
      width:140px;
      margin:0 auto 34px;
    }
    span{
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      color: #888888;
      display: block;
      text-align: center;
    }
  }
}
.ant-row-flex-space-between{
  display: block;
}
.ant-row-flex{
  display: block !important;
}
i{
  font: inherit;
}