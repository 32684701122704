#root {
  width: 100%;
  height: 100%;
}

p, div, dl, dt, dd, ul, li, ol, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

/* 提示消息全局统一样式 */

.ant-message-notice-content {
  background: rgba(0, 0, 0, 0.8) !important;
  color: #fff !important;
}
