.SchoolItemSumBudgetStat{
  .Task-top{
    width:100%;
    display: block;
    .ant-card{
      border:none;
      padding:0 18px 23px;
      display: block;
      background: #FFFFFF;
      box-shadow: 0px 4px 15px rgba(45, 64, 101, 0.08);
      .ant-card-body{
        padding:0;
        .title-h3{
          border-bottom: 1px solid #EEEEEE;
          display: block;
          padding:32px 0;
          text-align: center;
          width:100%;
          h3{
            display: inline-block;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 18px;
            height:20px;
            color: #2A283D;
            padding:0 16px 0 41px;
            //border-left:4px solid #40a9ff;
            margin-bottom:0;
            position: relative;
            &::after{
              position: absolute;
              left: 0;
              top: 50%;
              //height: 100%;
              //background: #40a9ff;
              //width: 4px;
              content: "";
              width:30px;
              height:30px;
              background: url('../../../../assets/img/synchrodatment/icon-active.png')no-repeat;
              background-size: 30px 30px;
              border-radius: 50%;
              margin-top:-15px;
            }
          }
        }
      }
    }
  }
  .TotalSchoolBudget-bottom {
    width: 100%;
    display: block;
    margin: 0 auto;
    .ant-card-body{
      padding: 0 0 8px;
      display: block;
    }
    .ant-card-bordered {
      border: none;
      .title-h3_lcl {
        display: block;
        padding-bottom:20px;
        text-align: center;
        width: 100%;
        border-bottom: 1px solid #f3f3f3;
        margin:20px 0 20px;
        .title-top {
          width: 100%;
          text-align: center;
          display: block;
          .title_lcl_change {
            display: inline-block;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;
            color: #2a283d;
            margin-bottom: 0;
            &:before {
              content: "";
              width: 4px;
              height: 14px;
              display: inline-block;
              margin-right: 15px;
              background: #40a9ff;
            }
          }
        }
      }
      .col-list{
        padding: 0 6%;
        .small-title{
          font-style: normal;
          font-weight: 550;
          font-size: 14px;
          color: #333;
          line-height: 20px;
          margin-bottom: 40px;
          position: relative;
          &::before {
            content: "";
            width: 8px;
            height: 8px;
            display: inline-block;
            margin-right: 12px;
            background: #40a9ff;
            position: absolute;
            left: -16px;
            top: 5px;
          }
        }
      }
      .col-list-table{
        padding: 0 0 17px;
        h5{
          text-align: left;
          width:70%;
          display: block;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #2a283d;
          margin-bottom: 20px;
          margin-left:45px;
        }
        .cont-table{
          margin-left:60px;
          .ant-table-row-cell-break-word{
            font-style: normal;
            font-size: 12px;
          }
          .ant-table-column-title{
            font-family:Microsoft YaHei!important;
            font-style: normal;
            font-weight: 550;
            font-size: 12px;
            line-height: 17px;
            text-align: center;
            color: #000000;
          }
          .ant-table-thead > tr > th{
            background: rgba(64, 169, 255, 0.05)!important;
          }
          .ant-table-body{
            //.ant-table-fixed{
            //  border: 1px solid #CCCCCC;
            //}
            // 滚动条设置
            &::-webkit-scrollbar {
              width: 5px;//y轴上的滚动条宽度
              height: 5px;//x轴上滚动条高度
            }
            &::-webkit-scrollbar-track {
              border-radius: 2px; /*滚动条的背景区域的圆角*/
              background-color:#f4f4f4; /*滚动条的背景颜色*/
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 2px; /*滚动条的圆角*/
              background-color:#40a9ff; /*滚动条的背景颜色*/
            }

            &::-webkit-scrollbar-thumb:hover {
              background-color:#40a9ff;
              cursor: pointer;
            }

            &::-webkit-scrollbar-thumb:active {
              background-color:#40a9ff;
              cursor: pointer;
            }
          }
        }
        .aggregate-cont{
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #EBEBEB;
          box-sizing: border-box;
          border-radius: 3px;
          padding:7px 20px;
          margin:20px auto;
          width:70%;
        }
      }
      .bottom-but{
        margin:0px auto;
        display: block;
        width:100%;
        padding:21px 11%;
        text-align: center;
        background: #FFFFFF;
        .but-li{
          padding:0 50px;
          background: #FFFFFF;
          border: 1px solid #EBEBEB;
          box-sizing: border-box;
          border-radius: 5px;
          height:41px;
          line-height: 39px;
          margin:0 12px;
          span{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            text-align: center;
            color: #888888;
          }
        }
        .ant-btn-primary{
          background: #40a9ff;
          border-radius: 5px;
          border: 1px solid #40a9ff;
          span{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;
            text-align: center;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}

