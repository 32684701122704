
.ant-scrolling-effect{
  div{.ant-modal-root{
    .ant-modal-wrap{.histy_modal_con{
      .ant-modal-content{
        .ant-modal-body{
          .quanxiansz_center{
            .content{
              height: 580px !important;
              .caozuogroup{
                .yuanxi{
                  margin-left: 0px !important;
                }
              }
            }
          }}
      }

    }

    }

  }}
}
.grxxquanxiansz_center{
  .content{
    overflow-y: auto;
    .caozuogroup{
      .yuanxi{
        margin: 0 0 15px 0 !important;
        border-bottom: 1px solid #EEEEEE;
        .title{
          .checkboxx {
            width: 24px;
            margin-top: 3px;
            margin-bottom: 24px;
          }
        }
        .checkgroup{
          .ant-checkbox-group-item{
            min-width: 133px;
            width: auto;
            margin-bottom: 22px;
          }
        }

      }
      .yuanxi:last-child{
        border-bottom: none;
      }
      .checkgroup{
        margin: 0 0 0px 20px !important;
      }
    }
  }
}
