.pointer {
  cursor: pointer;
}
.default{
  cursor: default;
}
//添加子专题 | 编辑 | 删除
.vertical_j {
  margin-bottom: 0;
  color: #3E78ED;
  span{
    cursor: pointer;
  }
  span:not(:last-child)::after {
    content: "|";
    margin: 0px 7px;
    color: #ccc;
    position: relative;
    bottom: 1px;
  }
}

//modal 按钮
.modal_btn_j {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    font-size: 16px;
    border-radius: 5px;
    width: 91px;
    height: 39px;
    &:nth-child(1) {
      color: #888888;
    }
    &:nth-child(2) {
      background: #3e78ed;
      border-radius: 5px;
      color: #fff;
      margin-left: 30px;
    }
  }
}

//red
.red {
  color: red;
  margin-right: 2px;
}

//取消排序按钮
.cancel_sort {
  border: 1px solid #3e78ed !important;
  border-radius: 3px;
  width: 90px;
  height: 32px;
  margin-right: 30px;
  span {
    color: #3e78ed;
    font-size: 14px;
  }
}
//确定排序
.sure_sort {
  background: #3e78ed !important;
  border-radius: 3px;
  color: #fff;
  margin-right: 20px;
  border: solid 1px #3e78ed !important;
}
//保存按钮
.save {
  color: #3e78ed;
  font-size: 12px;
}

//tab切换按钮
.tabs {
  .ant-tag {
    padding: 10px 15px;
    font-size: 15px;
    cursor: pointer;
    border: solid 1px #f3f3f3;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #fff;
    &.active {
      color: #3e78ed;
    }
  }
}

.ant_blue{
  background-color: #3E78ED !important;
  border: solid 1px #3E78ED !important;
  color: #fff !important;
}

.blue{
  color:#3E78ED ;
}

.padd {
  padding: 16px 20px;
  background-color: #fff;
}

.p20 {
  padding: 20px;
}

.m20 {
  margin-bottom: 20px;
}

.bg_fff {
  background-color: #fff;
  // min-height: calc(100vh - 131px);
}

.batch_box {
  position: absolute;
  bottom: 16px;
  left: 22px;
  > span {
    .ant-checkbox-wrapper {
      margin-right: 10px;
    }
    color: #333333;
  }
  .ant-btn{
    margin: 0px 10px;
  }
  .batch_mar{
    margin: 0 28px 0 10px;
    cursor: pointer;
  }
}

.pos_r{
  position: relative;
}
//重置按钮样式
.btn_box{
  .ant-btn[disabled]{
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    background: #fff!important;
    color: #888888!important;
    border-radius: 3px;
  }
  .ant-btn{
    border: 1px solid #3E78ED;
    border-radius: 3px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    color: #3E78ED;
  }
}

.resource_box{
  height: 100%;
}
.layout-right{
  min-height: calc(100vh - 121px) !important;
}

  .file_title_hyn{
    font-family: Noto Sans SC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #333333;
    position: relative;
    padding-left: 10px;
    margin: 37px 0;
    &:before{
      width: 3px;
      height: 14px;
      background: #3E78ED;
      border-radius: 10px;
      content: "";
      display: inline-block;
      vertical-align: -2px;
      margin-right: 9px;
    }
    > span {
      margin-left: 20px;
      color: #888;
    }
   > span span {
      display: inline-block;
      width: 15px;
      height: 15px;
      line-height: 15px;
      text-align: center;
      border-radius: 50%;
      background: #E0E0E0;
      font-size: 12px;
      color: #fff;
      margin-right: 6px;
    }
  }
  .upd_resource_form{margin-left: 15px}
/*资源详情页面start*/
.trans_box {
  width: 100%;
  background: #f6f7fb;
  padding-bottom: 80px;
  .header {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 43px;
    .back {
      width: 70px;
      cursor: pointer;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      .back-icon{
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    box-shadow: 0px 4px 15px rgba(45, 64, 101, 0.08);
    .title {
      flex: 1;
      display: flex;
      justify-content: center;
      h2 {
        color: #2a283d;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .show_box {
   /* width: 100%;*/
    padding: 0px 70px 40px;
    box-sizing: border-box;
    min-width: 1200px;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    .left {
      flex: 1;
      max-width: calc(100% - 400px);
      background: #fff;
      .watch_bottom {
        width: 100%;
        height: 55px;
        color: #0d1731;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        border-right: 1px solid #f3f3f3;
        .iconphoto{
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-right: 10px;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .end {
          display: flex;
          justify-content: flex-end;
        }
      }

    }
    .right {
      width: 400px;
      padding: 30px 19px 0px 19px;
      .right_top {
        border-bottom: 1px solid rgba(224, 224, 224, 0.5);
        padding-bottom: 27px;
        width: 100%;
        color: #333333;
        margin-bottom: 20px;
        .title {
          width: 100%;
          display: flex;
          h2 {
            color: #333333;
            font-size: 20px;
            font-weight: 550;
            margin-right: 16px;
          }
        }
        .auth {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            margin-right: 10px;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
        .affiliation{
.affiname{
  position: relative;
  &:after{
    content: ">";
    margin: 0 5px;
  }

}
        }
        .tag {
          margin-top: 12px;
          span {
            height: 30px;
            padding: 0px 10px;
            line-height: 30px;
            background: #e2ecff;
            border-radius: 3px;
            color: #3e78ed;
            display: inline-block;
          }
        }
      }
      .right_bottom {
        width: 100%;
        margin-bottom: 30px;
        h2 {
          color: #333333;
          margin-bottom: 11px;
          font-size: 14px;
          position: relative;
          padding-left: 12px;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 10%;
            width: 3px;
            border-radius: 1px;
            background: #3e78ed;
            height: 80%;
          }
        }
        p {
          color: #333333;
          margin-bottom: 0;
        }
        .morebtn{
          background: #FFFFFF;
          border: 1px solid #EBEBEB;
          box-sizing: border-box;
          border-radius: 5px;
          font-family: Alibaba PuHuiTi;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height:28px;
          height: 30px;
          text-align: center;
          color: #888888;
          float: right;
        }
      }
    }
  }
  .action_bttom {
    width: 100%;
    height: 80px;
    box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .action {
      margin-right: 80px;
      .ant-btn {
        margin-right: 30px;
      }
      .blue {
        color: #3e78ed;
        border: solid 1px #3e78ed;
      }
    }
    .sold-btn{
      border: 1px solid #3E78ED;
      border-radius: 5px;
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: #3E78ED;
      background: #fff;
    }
    .share-btn{
      background: #3E78ED;
      border-radius: 5px;
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: #FFFFFF;

    }
  }
  .xiajia_box{
    width: 100%;
    position: relative;
    p, span{
      margin: 0;
      padding: 0;
    }
    .xiajia_cont{
      box-sizing: border-box;
      position: relative;
      margin-left: 10px;
      z-index: 0;
      padding:0 0 30px 20px;
      &:last-child{
        /* .xiajia_header:after{
           border: none;
         }*/
        &:after{
          border: 0px dashed #fff;
        }
      }
      &:after{
        position: absolute;
        content: "";
        width: 1px;
        height: 100%;
        border: 1px dashed #FA6400;
        top: 5px;
        left: -2px;
      }

      .xiajia_header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px !important;
        position: relative;
        &:after{
          position: absolute;
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          border: 2px solid #FA6400;
          top: 5px;
          left: -25px;
          z-index: 99;
          background: #fff;
        }
        .xiajia_time{
          font-family: Noto Sans SC;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          display: flex;
          align-items: center;
          color: #333333;
        }
        .xiajia_name{
          font-family: Noto Sans SC;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          display: flex;
          align-items: center;
          color: #888888;
          min-width: 75px;
        }
      }
      .xiajia_desc{
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: #333333;
      }

    }
  }
}


/*资源详情页面end*/
.jindu {
  width: 30px;
  height: 30px;
  .ant-progress-inner {
    width: 30px !important;
    height: 30px !important;
    font-size: 18px !important;
    margin-left: 10px;
  }
  .ant-progress-text {
    font-size: 10px;
  }
}
/*检索条件*/
.top {
  width: 100%;
  height: 60px;
  //   border-bottom: 1px solid #eeeeee;
  line-height: 60px;
  display: flex;
  align-items: center;
  background: #fff;
  .add_btn {
    margin-right: 30px;
  }
  .sort {
    cursor: pointer;
  }
  .top_r {
    display: flex;
    .ant-input-affix-wrapper{
      width: 50%;
      margin-right: 20px;
    }
    .ant-input {
      width:100% !important;
    }
    .reset_btn {
      margin-right: 12px;
    }
  }
}
/*表格暂无数据*/
.global_table{
  border: 1px solid #f3f3f3;
  position: relative;
  .ant-pagination-options {
    position: absolute;
    right: 100%;
  }
  .ant-table-pagination.ant-pagination{
    position: relative;
   // left: 8%;
    margin-right: 15px;
  }
  .ant-pagination li, .ant-pagination a{
    border: none;
  }
  .ant-table-body{
    min-height: 400px;
  }
  .ant-table-placeholder{
    top:calc(50% + 27px);
    left: 50%;
    border: none!important;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
