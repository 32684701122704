.changeChildTask_center{
  margin-top: -5px;


  .contentTitle{
    height: 70px;
    //margin-left: 20px;
    span{
      font-size: 14px;
    }

    .contentTitleR{
      display: inline-block;
      margin-left: 17px;
      width: 260px;
      height: 40px;
    }

}



}
.contentTable{
     //max-height: 200px!important;
  height: 250px;
  margin-top: -10px;
  .ant-table-wrapper{
    .ant-spin-nested-loading{
      .ant-spin-container{
        .ant-table{
          .ant-table-content{
           .ant-table-body{
             max-height: 210px!important;
           }
          }

        }
      }
    }
  }

}

.TableRow {
  .ant-radio-checked::after{
    border: transparent;
  }
  .ant-radio-checked .ant-radio-inner{
    border-color: #40a9ff;
    margin-right: 20px;

  }
  .ant-radio-inner::after{
    background-color: #40a9ff;
  }

  .ant-table-selection-column{
    span{
      //margin-right: 0px;
    }

  }
  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
    border-color: #40a9ff;
  }

  .ant-modal-body .ant-table-tbody tr span{
    margin-right: -50px!important;
  }
  .ant-radio ant-radio-checked{

  }



}